<template>
  <div class="row">
    <div class="col-md-6 text-left">
      <span> Nakliye Bilgileri </span>
      <div>
        <addressCard
          v-if="advert"
          :data="{
            warehouseCity: advert.WarehouseCity,
            warehouseDistrict: advert.WarehouseDistrict,
            deliveryCompanyCity: advert.DeliveryCompanyCity,
            deliveryCompanyDistrict: advert.DeliveryCompanyDistrict,
            contactName: advert.ContactName,
            contactNumber: advert.ContactNumber,
            preventAccess: user.IsCarrier && advert ? !advert.Supplier : null,
            productType: advert.ProductType,
            deliveryCompanyCity: advert.DeliveryCompanyCity,
            deliveryCompanyDistrict: advert.DeliveryCompanyDistrict,
            warehouseCity: advert.WarehouseCity,
            warehouseDistrict: advert.WarehouseDistrict
          }"
        />
      </div>
    </div>
    <div class="col-md-6 text-left">
      <span>
        {{ user.IsCarrier ? "Talep Edilen Araç Bilgileri" : "Araç Bilgileri" }}
      </span>
      <vehicleDetail
        class="mt-3"
        v-if="advert"
        :vehicleInfo="{
          Vehicle: advert.Vehicle,
          vehicleName: advert.VehicleTypeName,
          vehicleCount: advert.VehicleCount,
          productWeight: advert.ProductWeight,
          details: advert.Details,
        }"
      />

      <!-- <div class="el-col el-col-24 mt-3">
        <span> Ödeme Yöntemi </span>
        <paymentMethod
          v-if="advert"
          :paymentMethod="{
            paymentType: advert.PaymentType,
          }"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import vehicleDetail from "../vehicleDetail/vehicleDetail";
// import paymentMethod from "../paymentMethod/paymentMethod";
import addressCard from "../addressCard/addressCard";
export default {
  props: ["advert"],
  components: {
    vehicleDetail,
    // paymentMethod,
    addressCard,
  },
  computed: {
    user() {
      return this.$store.getters["userState"];
    },
  },
};
</script>

<style scoped></style>
