var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.bid)?_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"offersSection"},[_c('div',{staticClass:"col-md-8 col-9 offers"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-8 offerDetail"},[_c('span',[_vm._v(" "+_vm._s(_vm.$moment(_vm.bid.CreatedDateTime).format("LLL"))+" ")]),_c('span',[_vm._v(" Sizin Teklifiniz ")]),_vm._m(1),_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v(" Toplam: "+_vm._s(_vm.bid.Price)+" ₺")])])])])]),_c('div',{staticClass:"offersSection left mt-2"},[(_vm.bid.Price2)?_c('div',{staticClass:"col-md-8 col-9 offers"},[_c('div',{staticClass:"row"},[_vm._m(2),_c('div',{staticClass:"col-8 offerDetail"},[_c('span',[_vm._v(" "+_vm._s(_vm.$moment(_vm.bid.Price2Date).format("LLL"))+" ")]),_c('span',[_vm._v(" Tedarikçi Firma Teklifi ")]),_vm._m(3),_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v(" Toplam: "+_vm._s(_vm.bid.Price2)+" ₺")])])])]):_vm._e()]),_c('div',{staticClass:"offersSection mt-2"},[(_vm.bid.Price3)?_c('div',{staticClass:"col-md-8 col-9 offers"},[_c('div',{staticClass:"row"},[_vm._m(4),_c('div',{staticClass:"col-8 offerDetail"},[_c('span',[_vm._v(_vm._s(_vm.$moment(_vm.bid.Price3Date).format("LLL"))+" ")]),_c('span',[_vm._v(" Sizin Teklifiniz ")]),_vm._m(5),_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v(" Toplam: "+_vm._s(_vm.bid.Price3)+" ₺")])])])]):_vm._e()])]):_c('div',[_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 offerImage"},[_c('img',{attrs:{"src":require("../../assets/icons/nkicon/gidenteklif.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"detailDivider"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 offerImage d-flex flex-column justify-content-center"},[_c('img',{attrs:{"src":require("../../assets/icons/nkicon/gelenteklif.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"detailDivider"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4 offerImage"},[_c('img',{attrs:{"src":require("../../assets/icons/nkicon/gidenteklif.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"detailDivider"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('img',{attrs:{"src":require("../../assets/images/empty_offer.svg"),"alt":""}}),_c('p',[_vm._v("Henüz bir teklifiniz bulunmamaktadır.")])])
}]

export { render, staticRenderFns }