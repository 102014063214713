<template>
  <div>
    <div class="row">
      <div class="col-md-6 mt-2">
        <div v-if="item.Status !== 2" class="offerCard">
          <div class="row">
            <div class="col-md-auto" style="height: 100px; overflow: hidden">
              <img :src="item.Image" alt="" :class="{ blur: !item.User.InvoiceInfo }" style="width: 100px" />
            </div>
            <div
              class="
                col-md-6
                d-flex
                justify-content-center
                align-items-start
                flex-column
              "
            >
              <span class="fs-20 font-w600">
                {{ item.Title }}
              </span>
              <!-- <span style="font-size: 20px; font-weight: 600">
                Birim Fiyatı:
                {{ item.Price3 == null ? item.Price : item.Price3 }}
                TL
              </span> -->
              <!-- <span style="font-size: 18px; font-weight: 500"> Ağırlık: {{ advertDetail.ProductWeight }} kg </span> -->
              <span style="font-size: 18px; font-weight: 500">
                Toplam Fiyat:
                {{ item.Price3 == null ? item.Price : item.Price3 }}₺
              </span>
              <div class="d-flex justify-content-start align-items-center">
                <img src="../../assets/icons/nkicon/star.svg" alt="" style="margin-right: 5px" />
                <span>
                  {{ item.User.AvgRate ? item.User.AvgRate : "Henüz oylanmadı" }}
                </span>
              </div>
            </div>
            <div class="col-md-3 d-flex align-items-center justify-content-end">
              <a
                href="javascript:;"
                @click="
                  () => {
                    carrierDetailDialog = true;
                  }
                "
              >
                <img src="../../assets/icons/nkicon/detail.svg" alt="" />
              </a>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12">
              <el-button
                @click="
                  () => {
                    counterofferDialog = true;
                  }
                "
                class="giveOffer"
              >
                <span style="font-size: 18px"> Pazarlık Yap </span>
              </el-button>
            </div>
            <div class="col-md-6 mt-2">
              <el-button
                @click="
                  () => {
                    offerRejectDialog = true;
                  }
                "
                class="rejectOffer"
              >
                <span style="font-size: 18px"> Reddet </span>
              </el-button>
            </div>
            <div class="col-md-6 mt-2">
              <el-button
                @click="
                  () => {
                    offerConfirmDialog = true;
                  }
                "
                class="approveOffer"
              >
                <span style="font-size: 18px"> Onayla </span>
              </el-button>
            </div>
          </div>
        </div>

        <el-dialog :close-on-click-modal="false" class="sm-modal" title="Teklifi Onayla" :visible.sync="offerConfirmDialog">
          <p class="text-white">
            Bu teklifi onaylamak istediğinize emin misiniz?
          </p>
          <!-- <span class="text-blue">Teklifi onayladığınızda eğer ki ödeme yöntemi GÖÇ ise doğrudan ödeme sayfasına yönlendirileceksiniz. Tüm Ödeme yöntemlerinde ilanınız doğrudan sevkiyatlar sayfasına taşınacaktır.</span> -->

          <span slot="footer" class="dialog-footer">
            <el-button class="bg-transparent" @click="offerConfirmDialog = false">Kapat</el-button>
            <el-button :loading="loading" class="bg-teal py-3 px-5" @click="confirmBid(item.Id)">Onayla</el-button>
          </span>
        </el-dialog>

        <el-dialog
          :close-on-click-modal="false"
          class="sm-modal"
          title="Yükünüzü Sigortalamayı Unutmayın!"
          :before-close="
            () => {
              this.$router.push({ name: 'Sevkiyatlarım' });
              insuranceDialog = false;
            }
          "
          :visible.sync="insuranceDialog"
        >
          <p class="text-white">
            Nakliyeci bilgileri sistemimizde yer almaktadır. Herhangi bir maddi olumsuzluğa karşı yükünüze sigorta yapmayı unutmayın!
          </p>
          <span slot="footer" class="dialog-footer" v-if="!item.Price2">
            <el-button
              :loading="loading"
              class="w-100 py-3"
              @click="
                () => {
                  this.$router.push({ name: 'Sevkiyatlarım' });
                  insuranceDialog = false;
                }
              "
            >
              Devam Et</el-button
            >
          </span>
        </el-dialog>

        <el-dialog :close-on-click-modal="false" class="sm-modal" title="Teklifi Reddet" :visible.sync="offerRejectDialog">
          <p class="text-white">
            Bu teklifi reddetmek istediğinize emin misiniz?
          </p>
          <span class="text-blue">Bu teklifi reddederseniz bu işlemin bir geri dönüşü olmayacaktır ve tekliflerinizde reddedildi olarak işaretlenecektir.</span>

          <span slot="footer" class="dialog-footer">
            <el-button class="bg-transparent" @click="offerRejectDialog = false">Kapat</el-button>
            <el-button :loading="loading" class="bg-pink py-3 px-5" @click="rejectOffer(item.Id)">Reddet</el-button>
          </span>
        </el-dialog>

        <el-dialog :close-on-click-modal="false" class="sm-modal" title="Pazarlık Yap" :visible.sync="counterofferDialog">
          <div class="row mb-3">
            <div class="offersSection">
              <div class="col-md-8 col-9 offers mb-3 left">
                <div class="row">
                  <div class="col-4 offerImage">
                    <img src="../../assets/icons/nkicon/gelenteklif.svg" alt="" />
                  </div>
                  <div class="col-8 offerDetail">
                    <span class="text-white"> {{ item.Price }} ₺ </span>
                  </div>
                </div>
              </div>
              <div class="col-md-8 col-9 offers mb-3" style="float: right" v-if="item.Price2">
                <div class="row">
                  <div class="col-4 offerImage">
                    <img src="../../assets/icons/nkicon/gidenteklif.svg" alt="" />
                  </div>
                  <div class="col-8 offerDetail">
                    <span class="text-white"> {{ item.Price2 }} ₺ </span>
                  </div>
                </div>
              </div>
              <div class="col-md-8 col-9 offers mb-3 left" style="float: left" v-if="item.Price3">
                <div class="row">
                  <div class="col-4 offerImage">
                    <img src="../../assets/icons/nkicon/gelenteklif.svg" alt="" />
                  </div>
                  <div class="col-8 offerDetail">
                    <span class="text-white"> {{ item.Price3 }} ₺ </span>
                  </div>
                </div>
              </div>
              <div v-if="!item.Price2" class="col-md-8" style="float: right">
                <el-input class="form-control" placeholder="Lütfen Bir Teklif Giriniz" :value="newoffer" @input="updateOffer"> </el-input>
              </div>
            </div>
          </div>
          <div v-if="!item.Price2" class="d-flex flex-column">
            <label>
              İlan teklifi TL olarak verilir.<br />
              <!-- (Örneğin: 60,100,140) -->
            </label>
            <span class="mb-2"> Toplam: {{ totalPrice }}₺</span>
          </div>
          <span slot="footer" class="dialog-footer" v-if="!item.Price2">
            <el-button :loading="loading" class="w-100 py-3" @click="sendCounterBid(item.Id)"> Yeni Teklif Ver</el-button>
          </span>
        </el-dialog>

        <el-dialog :close-on-click-modal="false" class="sm-modal" title="Ödeme" :visible.sync="paymentDialog">
          <span class="text-blue">Bu sayfada verdiğiniz bilgiler hiçbir şekilde sistem tarafından kaydedilmemekte olup, iyzico güvencesiyle alınan ödemelerin sadece ücretleri tarafımıza aktarılmaktadır.</span>

          <div class="row">
            <div class="col-12 mb-3">
              <label> Kartın Üzerinde Yazan Ad Soyad</label>
              <el-input class="form-control" placeholder="Ad Soyad"></el-input>
            </div>
            <div class="col-6 mb-3">
              <label>Ay/Yıl</label>
              <el-input class="form-control" placeholder="AA/YY"></el-input>
            </div>
            <div class="col-6 mb-3">
              <label>CVV/CVC</label>
              <el-input class="form-control" placeholder="***"></el-input>
            </div>
            <div class="col-12 mb-3">
              <label>Kart Numarası</label>
              <el-input class="form-control" placeholder="____ ____ ____ ____"> </el-input>
            </div>
            <div class="col-12 mb-3">
              <small class="text-white">Tutasssr:</small>
              <strong class="text-white fs-18">26dds00₺</strong>
            </div>
            <div class="col-12">
              <div class="d-flex">
                <input type="checkbox" @click="handleCheckbox" v-model="contract" class="form-check-input" style="width: 1.25rem !important; height: 1.25rem !important" />
                <label class="form-check-label">
                  <span @click="contractDialog = true" class="policy-text">Mesafeli Satış Sözleşmesini</span>
                  okudum, anladım kabul ediyorum.</label
                >
              </div>
            </div>
          </div>

          <span slot="footer" class="dialog-footer">
            <el-button class="py-3 w-100" @click="paymentDialog = false">Onayla</el-button>
          </span>
        </el-dialog>

        <el-dialog :close-on-click-modal="false" class="sm-modal" :title="contractInfo.Title" :visible.sync="contractDialog">
          <div v-html="contractInfo.Content"></div>
          <el-button class="accept-button m-0" @click="handleAcceptPolicy"> Kabul et ve kapat</el-button>
        </el-dialog>

        <el-dialog :close-on-click-modal="false" title="Şoför Bilgileri" :visible.sync="carrierDetailDialog">
          <div class="col-md-12 d-flex justify-content-end flex-row">
            <div style="cursor: pointer" @click="handleOpenBlockSupplier">
              <label for="blockUser" class="m-1" style="cursor: pointer">Kullanıcıyı engelle</label>
              <img name="blockUser" src="../../assets/icons/nkicon/engelle.svg" alt="" class="block-user m-1" />
            </div>
          </div>
          <el-row class="text-white mb-4">
            <driver-detail :driverInfo="item.Vehicle" :rate="item.User.AvgRate ? item.User.AvgRate : 'Henüz oylanmadı'" :preventAccess="!item.User.InvoiceInfo" />
          </el-row>
          <el-row class="px-2 mb-3">
            <h5>Kişisel Bilgiler</h5>
            <el-row v-if="!item.User.InvoiceInfo" class="blur-text text-center mt-0 w-100 h-100">
              <el-col class="h-100">
                <div 
                  class="w-100 h-100 d-flex align-items-center justify-content-center px-2"               
                  @click="
                    if ($store.state.User.token) {
                      infoPaymentDialog = true;
                    } else {
                      $store.commit('toggleLoginDialog', true);
                    }
                  "
                  style="cursor: pointer;"
                >
                  <a
                    class="text-white px-2"
                    href="javascript:;"
                    style="width: 360px;"
                  >
                    Nakliyeci iletişim bilgisine hemen erişmek için {{ nakuAmount }}x
                    <img style="width: 50px" src="../../assets/images/naku.svg" alt="" />
                    ödeyiniz.
                  </a>
                </div>
              </el-col>
            </el-row>
            <el-col :span="24" :class="{ blur: !item.User.InvoiceInfo }">
              <el-row class="carrierContainer p-3 rounded">
                <el-col :span="24" class="carrierBox mb-2 rounded p-3 d-flex flex-column">
                  <h4>Adı Soyadı</h4>
                  <span>{{ item.User.InvoiceInfo ? item.Vehicle.DriverName: "*****" }}</span>
                </el-col>
                <el-col :span="24" class="carrierBox mb-2 rounded p-3 d-flex flex-column">
                  <h4>Telefon</h4>
                  <span>{{ item.User.InvoiceInfo ? item.Vehicle.DriverPhone : "*****" }}</span>
                </el-col>
                <el-col :span="11" class="carrierBox mb-2 rounded p-3 d-flex flex-column">
                  <h4>Araç Plaka</h4>
                  <span>{{ item.User.InvoiceInfo && item.Vehicle.PlateNo ? item.Vehicle.PlateNo : "*****" }}</span>
                </el-col>
                <el-col :span="2">&nbsp;</el-col>
                <el-col :span="11" class="carrierBox mb-2 rounded p-3 d-flex flex-column">
                  <h4>Dorse Plaka</h4>
                  <span>{{ item.User.InvoiceInfo && item.Vehicle.TrailerPlateNo ? item.Vehicle.TrailerPlateNo : "*****" }}</span>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row class="px-2 mb-3">
            <h5>Araç Bilgileri</h5>
            <vehicle-detail :vehicleInfo="item" />
          </el-row>
          <el-row>
            <el-col>
              <photo-detail :photoDetail="item.Vehicle" :preventAccess="!item.User.InvoiceInfo" />
            </el-col>
          </el-row>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" class="sm-modal" title="Kullanıcıyı engelle" :visible.sync="blockSupplierModal">
          <p class="block-dialog">
            Yaşadığınız olay için çok üzgünüz. İletmek istediğiniz bir probleminiz varsa bizimle destek@nakliyekullan.com üzerinden iletişime geçebilirsiniz.
          </p>
          <br />
          <p class="block-dialog">
            Bu kullancıyı engellemek istediğinize emin misiniz?
          </p>
          <span slot="footer" class="dialog-footer">
            <div class="d-flex justify-content-center">
              <el-button class="w-100 py-3 cancel-button" @click="blockSupplierModal = false"> İptal</el-button>
              <el-button :loading="loading" class="w-100 py-3 block-button" @click="handleBlockUser(item.BidOwnerId)"> Engelle</el-button>
            </div>
          </span>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" class="sm-modal" title="Firma Bilgileri Görüntüle" :visible.sync="infoPaymentDialog">
          <p class="text-white">Bu işlem karşılığında hesabınızdan {{ nakuAmount }}x NAKU kullanılacaktır. Bu işlemi onaylıyor musunuz?</p>
          <div class="d-flex justify-content-center align-items-center text-white">
            <span class="fs-20 fw-bold"> {{ nakuAmount }}x</span>
            <img src="../../assets/images/naku.svg" alt="" style="width: 120px" />
          </div>
          <span slot="footer" class="dialog-footer d-flex">
            <el-button class="bg-transparent px-5 py-3" @click="infoPaymentDialog = false">İptal</el-button>
            <el-button class="pay-button" type="primary" @click="payNakuForDetails(item.Id)"
              ><span class="fw-bold"> {{ nakuAmount }}x</span>
              <img src="../../assets/images/naku.svg" alt="" style="width: 20px" />
              Öde ve Görüntüle</el-button
            >
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import DriverDetail from "../driverDetail/driverDetail.vue";
import PhotoDetail from "../photoDetail/photoDetail.vue";
import VehicleDetail from "../vehicleDetail/vehicleDetail.vue";
import POLICIES from "../../constants/policies";

export default {
  name: "OfferCard",
  props: ["item", "advertDetail", "nakuAmount"],
  components: {
    DriverDetail,
    VehicleDetail,
    PhotoDetail,
  },
  data() {
    return {
      offerConfirmDialog: false,
      offerRejectDialog: false,
      contractDialog: false,
      paymentDialog: false,
      checked: false,
      newoffer: "",
      driverInfoDialog: false,
      carrierDetailDialog: false,
      counterofferDialog: false,
      blockSupplierModal: false,
      advertId: "",
      infoPaymentDialog: false,
      insuranceDialog: false,
      contract: false,
      totalPrice: 0.0,
    };
  },
  methods: {
    updateTotalPrice() {
      const formattedUnitPrice = parseFloat(this.newoffer);
      if (formattedUnitPrice) this.totalPrice = formattedUnitPrice;
      else this.totalPrice = 0.0;
    },
    updateOffer(e) {
      let dotCount = e.split(".").length - 1;
      if (!e || dotCount <= 1) {
        this.newoffer = e.replace(/[^.\d]/g, "");
        this.updateTotalPrice();
      }
    },
    handleCheckbox(e) {
      window.sendNotification.warning("Onaylamak için okumanız gerekmektedir");
      e.preventDefault();
      e.stopPropagation();
    },
    handleAcceptPolicy() {
      this.contractDialog = false;
      this.contract = true;
    },
    async payNakuForDetails(bidID) {
      const result = await this.$store.dispatch("payNakuForDetails", {
        advertId: { AdvertId: this.advertDetail.ID },
        id: {
          Id: bidID,
        },
      });
      if (!result.HasError) this.infoPaymentDialog = false;
    },
    handleBlockUser(bidOwnerId) {
      this.$store.dispatch("blockUser", { UserId: bidOwnerId });
      this.handleOpenBlockSupplier();
    },
    handleOpenBlockSupplier() {
      if (this.$store.state.User.token) {
        this.blockSupplierModal = !this.blockSupplierModal;
      } else {
        this.$store.commit("toggleLoginDialog", true);
      }
    },
    async confirmBid(bidID) {
      const result = await this.$store.dispatch("confirmBidForSupplier", {
        bidId: { BidID: bidID, VehicleId: this.item.VehicleId },
        paymentType: this.advertDetail.PaymentType,
      });
      this.paymentDialog = false;
      this.offerConfirmDialog = false;
      if (!result.HasError) this.insuranceDialog = true;
    },
    async rejectOffer(bidID) {
      const result = await this.$store.dispatch("rejectBidForSupplier", {
        AdvertId: { AdvertId: this.advertDetail.ID },
        bidId: { BidID: bidID },
      });
      if (!result.HasError) this.offerRejectDialog = false;
    },
    async sendCounterBid(bidID) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      await this.$store.dispatch("sendCounterBid", {
        BidId: bidID,
        Price: this.newoffer,
        adId: params.adId,
      });
      this.counterofferDialog = false;
    },
  },
  computed: {
    contractInfo: {
      get() {
        const cookie = this.$store.getters["policies"].find((policy) => policy.Type === POLICIES.PAYMENT);
        return cookie ? cookie : { Title: "", Content: "" };
      },
    },
    loading() {
      return this.$store.getters["loading"];
    },
  },
};
</script>

<style lang="scss">
.pay-button {
  white-space: normal !important;
  word-wrap: break-word;
}
.blur-text {
  position: absolute;
  z-index: 999;
}
.offerCard {
  padding: 15px;
  border-radius: 8px;
  border: 2px solid #82c0cc;
  background-color: #4d668a;
  width: 100%;
}
.offers {
  padding: 15px;
  background-color: #4d668a;
  border: 1px solid transparent;
  border-radius: 16px;
  .row {
    height: 100%;
    .offerImage {
      display: flex;
      align-items: center;
      img {
        width: 60%;
      }
    }
    .offerDetail {
      height: 100%;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
    }
  }
}
.left {
  justify-content: flex-start !important;
  .row {
    flex-direction: row-reverse;
    .offerImage {
      justify-content: flex-end;
    }
    .offerDetail {
      align-items: flex-start !important;
    }
  }
}
.el-dialog__body {
  padding: 10px 20px;
}
.offerDetail {
  height: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.giveOffer {
  width: 100%;
  height: 45px;
  border-radius: 8px;
  background-color: #82c0cc;
  border: none;
}

.giveOffer:hover,
.giveOffer:focus {
  background-color: #82c0cc;
  border: none;
}

.rejectOffer {
  width: 100%;
  height: 45px;
  border-radius: 8px;
  background-color: #ff6978;
  border: none;
}

.rejectOffer:hover,
.rejectOffer:focus {
  background-color: #ff6978;
  border: none;
}

.approveOffer {
  width: 100%;
  height: 45px;
  border-radius: 8px;
  background-color: #91d886;
  border: none;
}

.approveOffer:hover,
.approveOffer:focus {
  background-color: #91d886;
  border: none;
}
.blur-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 150px;
  height: 100%;
  border: none;
  background-color: black;
  opacity: 0.3;
}
.carrierImage {
  filter: blur(4px);
  -webkit-filter: blur(4px);
}
.carrierContainer {
  background-color: #4d668a;
}
.carrierBox {
  background-color: #2d405a;
  color: white;
  .editButton {
    float: right;
  }
}
</style>
