var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 text-left"},[_c('span',[_vm._v(" Nakliye Bilgileri ")]),_c('div',[(_vm.advert)?_c('addressCard',{attrs:{"data":{
          warehouseCity: _vm.advert.WarehouseCity,
          warehouseDistrict: _vm.advert.WarehouseDistrict,
          deliveryCompanyCity: _vm.advert.DeliveryCompanyCity,
          deliveryCompanyDistrict: _vm.advert.DeliveryCompanyDistrict,
          contactName: _vm.advert.ContactName,
          contactNumber: _vm.advert.ContactNumber,
          preventAccess: _vm.user.IsCarrier && _vm.advert ? !_vm.advert.Supplier : null,
          productType: _vm.advert.ProductType,
          deliveryCompanyCity: _vm.advert.DeliveryCompanyCity,
          deliveryCompanyDistrict: _vm.advert.DeliveryCompanyDistrict,
          warehouseCity: _vm.advert.WarehouseCity,
          warehouseDistrict: _vm.advert.WarehouseDistrict
        }}}):_vm._e()],1)]),_c('div',{staticClass:"col-md-6 text-left"},[_c('span',[_vm._v(" "+_vm._s(_vm.user.IsCarrier ? "Talep Edilen Araç Bilgileri" : "Araç Bilgileri")+" ")]),(_vm.advert)?_c('vehicleDetail',{staticClass:"mt-3",attrs:{"vehicleInfo":{
        Vehicle: _vm.advert.Vehicle,
        vehicleName: _vm.advert.VehicleTypeName,
        vehicleCount: _vm.advert.VehicleCount,
        productWeight: _vm.advert.ProductWeight,
        details: _vm.advert.Details,
      }}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }