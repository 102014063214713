<template>
  <div v-if="advertInfo && advertInfo.ID" class="container mb-3" id="details">
    <div class="row">
      <div class="col-md-12">
        <div class="tabBackground d-flex">
          <div class="w-50 px-3 d-flex justify-content-center align-items-center">
            <span style="cursor: pointer" class="w-100 text-center" :class="activeTab == 0 ? 'activeTab' : null" @click="selectTab(0)" id="detail">
              İlan Detayı
            </span>
          </div>
          <div class="w-50 px-3 d-flex justify-content-center align-items-center">
            <span class="w-100 text-center" style="cursor: pointer" @click="selectTab(1)" id="offer">
              Teklifler
            </span>
          </div>
        </div>
        <div class="row mt-3">
          <div>
            <span>Son Tarih:</span>
            <span style="color: #ff6978">
              {{
                $moment(advertInfo ? advertInfo.AdvertDate : null)
                  .add(1, "days")
                  .format("LLL")
              }}
            </span>
          </div>
          <div class="col-md-6 text-left">
            <span> #{{ advertInfo ? advertInfo.AdvertNo : null }} </span>
          </div>
          <div class="col-md-6 d-flex justify-content-end" v-if="user && !user.IsCarrier">
            <div class="d-flex align-items-center">
              <img style="cursor: pointer" @click="handleOpenEditAd" src="../../assets/icons/nkicon/pen.svg" alt="" class="editButton" />
              <div class="buttonDivider"></div>
              <img style="cursor: pointer" @click="handleDeleteAdvertModal" src="../../assets/icons/nkicon/thrash.svg" alt="" />
            </div>
          </div>
          <div v-else class="col-md-6 d-flex justify-content-end flex-row">
            <div style="cursor: pointer" @click="handleOpenBlockSupplier">
              <label for="blockUser" class="m-1" style="cursor: pointer">Kullanıcıyı engelle</label>
              <img name="blockUser" src="../../assets/icons/nkicon/engelle.svg" alt="" class="block-user m-1" />
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <el-button
              class="bg-blue py-3"
              style="margin-top: 12px"
              @click="
                () => {
                  $router.push({ path: '/ilanlar/aktif' });
                }
              "
              >Geri Dön</el-button
            >
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-6 text-left">
            <span v-if="advertInfo" style="font-size: 25px">
              {{ advertInfo.ProductType.charAt(0).toUpperCase() + advertInfo.ProductType.slice(1) }}
            </span>
          </div>
        </div>
        <div v-if="activeTab === 0" class="mt-3">
          <personal-detail v-if="user.IsCarrier" 
            :personalInfo="{
              ...advertInfo,
              Supplier: true
            }"
            :nakuAmount="systemSettings.CarrierContactInfoNakuAmount" 
            :adId="advertInfo ? advertInfo.ID : null" 
            class="mb-3" />
          <ad-details :advert="advertInfo"></ad-details>
          <div v-if="user.IsCarrier" class="col-md-12 d-flex justify-content-center">
            <div class="w-100" v-if="carrierBids && (carrierBids.length === 0 || (carrierBids.length > 0 && carrierBids[0].Price2 !== null && carrierBids[0].Price3 === null))">
              <el-button v-if="carrierBids && carrierBids[0] ? carrierBids[0].Price : false" class="pay-button w-100 mt-3" type="primary" @click="selectTab(1)"> Yeni Teklif Ver</el-button>
              <div v-else class="w-100">
                <el-button class="pay-button w-100 mt-3" type="primary" @click="selectTab(1)"
                  ><span class="fw-bold"> {{ systemSettings.BidNakuAmount }}x</span> <img src="../../assets/images/naku.svg" alt="" style="width: 20px" />Öde ve Teklif Ver</el-button
                >
              </div>
            </div>
          </div>
          <div v-else class="w-100">
            <el-button class="pay-button w-100 mt-3" type="primary" @click="selectTab(1)">Tekliflerime Git</el-button>
          </div>
        </div>
        <div v-else>
          <div class="row">
            <div class="col-md-12 mt-2 text-left">
              <span style="font-size: 20px"> Teklifler </span>
            </div>
          </div>
          <div v-if="!user.IsCarrier" class="row">
            <not-found-info :item="filteredBidsInfo" :title="'Teklif'" :emptyName="'offer'" />
            <div v-for="(bid, index) in filteredBidsInfo" :key="index">
              <offerCard :advertDetail="advertInfo" :nakuAmount="systemSettings.CompanyContactInfoNakuAmount" :item="bid" />
            </div>
          </div>
          <div v-else>
            <offersDetail v-for="(bid, index) in carrierBids" :bid="bid" :advertInfo="advertInfo" :key="index" />
            <div class="d-flex" style="float: right">
              <el-button :loading="loading" @click="offerConfirmDialog = true" class="bg-teal py-3" v-if="carrierBids && carrierBids.length > 0 && carrierBids[0].Price2 != null && carrierBids[0].Price3 == null">Teklifi Kabul Et | {{ carrierBids[0].Price2 }} ₺</el-button>
              <el-button class="offer-bg py-3" @click="sendCounterBid" v-if="carrierBids && (carrierBids.length === 0 || (carrierBids.length > 0 && carrierBids[0].Price2 !== null && carrierBids[0].Price3 === null))">Teklif Ver</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :close-on-click-modal="false" class="sm-modal" title="Teklifi Onayla" :visible.sync="offerConfirmDialog">
      <p class="text-white">Bu teklifi onaylamak istediğinize emin misiniz?</p>
      <!-- <span class="text-blue"
            >Teklifi onayladığınızda eğer ki ödeme yöntemi GÖÇ ise doğrudan
            ödeme sayfasına yönlendirileceksiniz. Tüm Ödeme yöntemlerinde
            ilanınız doğrudan sevkiyatlar sayfasına taşınacaktır.</span
          > -->

      <span slot="footer" class="dialog-footer">
        <el-button class="bg-transparent" @click="offerConfirmDialog = false">Kapat</el-button>
        <el-button :loading="loading" class="bg-teal py-3 px-5" @click="confirmBidForCarrier">Onayla</el-button>
      </span>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" class="sm-modal" title="Teklif Ver" :visible.sync="bidModal">
      <div class="row mb-3">
        <div class="col-md-12" style="float: right">
          <div class="d-flex flex-column justify-content-start">
            <label>
              İlan teklifi TL olarak verilir.<br />
              <!-- (Örneğin: 60,100,140) -->
            </label>
            <span> Taşınacak yükün ağırlığı:{{ advertInfo.ProductWeight }} kg</span>
          </div>
          <el-input
            class="form-control mt-2"
            :class="{
              'input-validation-error': sendBidResponse.ValidationErrors.some((x) => x.Key == 'Price'),
            }"
            placeholder="Lütfen Bir Teklif Giriniz"
            :value="bidFormInputs.Price"
            @input="updateUnitPrice"
            @keypress.enter.native="sendBid"
            min="0"
            maxlength="12"
          >
          </el-input>
          <span class="validation-error" v-if="sendBidResponse.ValidationErrors.some((x) => x.Key == 'Price')">{{ sendBidResponse.ValidationErrors.filter((x) => x.Key == "Price")[0].Value }}</span>
          <div>
            <label class="mt-3"> Araç Seçin: </label>
            <el-select
              v-if="$store.state.User.careerVehicles"
              v-model="bidFormInputs.VehicleId"
              :placeholder="suitableVehicles.length ? 'Araç Seçiniz...' : 'Uygun aracınız yok!'"
              class="mt-2 form-control"
              :class="{
                'input-validation-error': sendBidResponse.ValidationErrors.some((x) => x.Key == 'VehicleId'),
              }"
            >
              <el-option v-for="item in suitableVehicles" :key="item.ID" :label="item.Brand + ' / ' + item.Model + ' ' + item.PlateNo" :value="item.ID"> </el-option>
            </el-select>
            <span class="validation-error" v-if="sendBidResponse.ValidationErrors.some((x) => x.Key == 'VehicleId')">{{ sendBidResponse.ValidationErrors.filter((x) => x.Key == "VehicleId")[0].Value }}</span>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <span
          slot="footer"
          class="
            dialog-footer
            d-flex
            justify-content-center
            align-items-center
            mb-4
          "
        >
          Toplam: {{ totalPrice }} ₺
        </span>
        <el-button v-if="carrierBids && carrierBids[0] ? carrierBids[0].Price : false" :loading="loading" class="w-100 py-3" @click="sendBid"> Yeni Teklif Ver</el-button>
        <div v-else class="w-100">
          <el-button type="primary" :loading="loading" class="w-100 py-3" @click="sendBid"
            ><span class="fw-bold"> {{ systemSettings.BidNakuAmount }}x</span> <img src="../../assets/images/naku.svg" alt="" style="width: 20px" />Öde ve Yeni Teklif Ver</el-button
          >
        </div>
      </span>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" class="sm-modal" title="İlanı sil" :visible.sync="deleteAdvertModal">
      <div><b>İlanı silmek istediğinize emin misiniz?</b></div>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="loading" class="w-100 py-3" @click="handleDeleteAdvert"> Sil</el-button>
      </span>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" class="sm-modal" title="Kullanıcıyı engelle" :visible.sync="blockSupplierModal">
      <p class="block-dialog">
        Yaşadığınız olay için çok üzgünüz. İletmek istediğiniz bir probleminiz varsa bizimle destek@nakliyekullan.com üzerinden iletişime geçebilirsiniz.
      </p>
      <br />
      <p class="block-dialog">
        Bu kullancıyı engellemek istediğinize emin misiniz?
      </p>
      <span slot="footer" class="dialog-footer">
        <div class="d-flex justify-content-center">
          <el-button class="w-100 py-3 cancel-button" @click="blockSupplierModal = false"> İptal</el-button>
          <el-button :loading="loading" class="w-100 py-3 block-button" @click="handleBlockUser"> Engelle</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as $ from "jquery";
import offerCard from "../../components/offerCard/offerCard";
import adDetails from "../../components/adDetails/adDetails";
import offersDetail from "../../components/offersDetail/offersDetail";
import NotFoundInfo from "../../components/notFound/notFoundInfo.vue";
import { mapGetters } from "vuex";
import PersonalDetail from "../../components/personalDetail/personalDetail.vue";

export default {
  name: "Details",
  props: ["detail"],
  data() {
    return {
      activeTab: 0,
      adId: null,
      carrierBids: null,
      deleteAdvertModal: false,
      blockSupplierModal: false,
      bidFormInputs: { AdvertId: null, Price: null, VehicleId: null },
      offerConfirmDialog: false,
      totalPrice: 0.0,
    };
  },

  components: {
    offerCard,
    adDetails,
    offersDetail,
    PersonalDetail,
    NotFoundInfo,
  },

  async created() {
    if (this.bidFormInputs) this.bidFormInputs.Price = null;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    this.adId = params.adId;
    await this.$store.dispatch("getAdvertById", {
      adId: params.adId,
    });
  },

  computed: {
    ...mapGetters({
      advertInfo: "singleAdvertDetail",
      user: "userState",
      loading: "loading",
      systemSettings: "systemSettings",
    }),
    filteredBidsInfo: {
      get() {
        return this.$store.getters["bidsInfo"].filter((item) => item.Status !== 2);
      },
    },
    suitableVehicles: {
      get() {
        return this.advertInfo?.TrailerIds.length ? this.$store.state.User.careerVehicles.filter((x) => x.Status === 0 && this.advertInfo?.TrailerIds.includes(x.TrailerId)) : this.$store.state.User.careerVehicles;
      },
    },
    bidModal: {
      get() {
        return this.$store.getters["bidModal"];
      },
      set(newValue) {
        this.$store.commit("bidModal", newValue);
      },
    },
    sendBidResponse() {
      return this.$store.getters["sendBidResponse"];
    },
  },

  async mounted() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (this.user.IsCarrier == false) {
      this.$store.dispatch("listAdvertBidsForSupplier", {
        AdvertId: params.adId,
      });
    }
    $("#detail").addClass("activeTab");
  },
  updated() {
    if (this.advertInfo && !this.advertInfo.ID) this.$router.push({ path: "/ilanlar/aktif" });
  },
  methods: {
    updateTotalPrice() {
      const formattedUnitPrice = parseFloat(this.bidFormInputs.Price);
      if (formattedUnitPrice) this.totalPrice = formattedUnitPrice;
      else this.totalPrice = 0.0;
    },
    updateUnitPrice(e) {
      let dotCount = e.split(".").length - 1;
      if (!e || dotCount <= 1) {
        this.bidFormInputs.Price = e.replace(/[^\d]/g, "");
        this.updateTotalPrice();
      }
    },
    handleBlockUser() {
      this.$store.dispatch("blockUser", { UserId: this.advertInfo.UserId });
      this.handleOpenBlockSupplier();
    },
    handleOpenBlockSupplier() {
      if (this.$store.state.User.token) {
        this.blockSupplierModal = !this.blockSupplierModal;
      } else {
        this.$store.commit("toggleLoginDialog", true);
      }
    },
    handleDeleteAdvertModal() {
      this.deleteAdvertModal = !this.deleteAdvertModal;
    },
    handleDeleteAdvert() {
      this.$store.dispatch("deleteAdvertById", { ID: this.advertInfo.ID });
      this.handleDeleteAdvertModal();
    },
    confirmBidForCarrier() {
      this.$store.dispatch("confirmBidForCarrier", {
        BidId: this.carrierBids[0].ID,
        VehicleId: this.carrierBids[0].VehicleId,
      });
    },
    sendBid() {
      if (this.carrierBids && this.carrierBids.length > 0 && this.carrierBids[0]) {
        this.$store.dispatch("sendFinalBid", {
          BidId: this.carrierBids[0].ID,
          Price: this.bidFormInputs.Price,
          AdvertId: this.adId,
        });
      } else {
        const inputs = { ...this.bidFormInputs };
        inputs.Price = this.bidFormInputs.Price;
        this.$store.dispatch("sendBid", {
          ...inputs,
          AdvertId: this.adId,
        });
      }
    },
    selectTab(tab) {
      this.activeTab = tab;
    },
    sendCounterBid() {
      if (this.$store.state.User.token) {
        this.bidModal = true;
      } else {
        this.$store.commit("toggleLoginDialog", true);
      }
    },
    handleOpenEditAd() {
      this.$store.commit("openEditAd", {
        advert: this.advertInfo,
        method: "update",
      });
    },
  },
  watch: {
    activeTab(val) {
      if (val === 0) {
        $("#offer").removeClass("activeTab");
        $("#detail").addClass("activeTab");
      } else {
        $("#offer").addClass("activeTab");
        $("#detail").removeClass("activeTab");
      }
    },
    advertInfo() {
      this.carrierBids = this.advertInfo && this.advertInfo.Bids ? this.advertInfo.Bids.filter((x) => x.BidOwnerId == this.user.ID) : [];
    },
  },
};
</script>

<style lang="scss" scoped>
.block-dialog {
  color: rgba(245, 245, 245, 0.747) !important;
}
#details span {
  color: #ffffff;
  font-size: 14px;
}
.tabBackground {
  border-radius: 16px;
  background-color: #4d668a;
  padding: 10px 0;
}
.activeTab {
  background-color: #2d405a;
  border-radius: 16px;
  display: inline-block;
  padding: 10px 0;
  width: 100%;
  text-align: center;
}
.buttonDivider {
  border: 1px solid white;
  margin: 0 20px;
  height: 100%;
}
.block-button {
  background-color: palevioletred;
}
.cancel-button {
  background-color: transparent;
}
.block-user {
  height: 25px;
  width: 25px;
}
.pay-button {
  white-space: normal !important;
  word-wrap: break-word;
  background-color: #e9501c;
}
.offer-bg {
  background-color: #82c0cc;
}
</style>
